<template>
  <div class="py-4">
    <b-overlay :show="isProcessing">
      <b-card class="w-50 m-auto">
        <validation-observer ref="shareWalletProcessForm">
          <b-card
            v-if="invalid"
            class="text-center mt-4 w-50 mx-auto"
          >
            <div
              class="d-flex justify-content-center align-items-center"
              style="min-height: 300px"
            >
              <div>
                <feather-icon
                  icon="FrownIcon"
                  size="72"
                  color="#ff9f43"
                />
                <h2 class="mt-1">
                  {{ $t('Oops') }}!!
                </h2>

                <h5 class="mb-3">
                  {{ $t('Looks like the link is broken.') }}
                </h5>
                <b-button
                  variant="primary"
                  class="cursor-pointer"
                  @click="$router.replace({ name: 'login'})"
                >
                  {{ $t('Go to login') }}
                </b-button>
              </div>
            </div>
          </b-card>
          <div v-else>
            <div v-if="!decline">
              <div class="text-center my-4">
                <wz-logo />
              </div>
              <div>
                <validation-provider
                  #default="{errors}"
                  rules="required"
                  name="alias"
                >
                  <b-form-group :label="$t('Alias for Shared Account')">
                    <b-form-input
                      v-model="description"
                      type="text"
                      placeholder="Alias"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

                <b-button
                  variant="primary"
                  class="float-right"
                  @click="approveWalletShare"
                >
                  {{ $t('Submit') }}
                </b-button>
              </div>
            </div>
            <div v-else>
              <p>{{ $t('messages.decline-invitation-confirmation') }}</p>
              <b-button
                variant="danger"
                class="float-right"
                @click="approveWalletShare"
              >{{ $t('Confirm') }}</b-button>
            </div>
          </div>
        </validation-observer>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import useApollo from '@/plugins/graphql/useApollo'
import {
  BCard, BFormGroup, BFormInput, BButton, BOverlay,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import WzLogo from '@core/layouts/components/Logo.vue'

export default {
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    ValidationObserver,
    ValidationProvider,
    WzLogo,
  },
  data() {
    return {
      required,
      description: '',
      isProcessing: false,
      decline: Boolean(this.$route.query.decline) ?? false,
      invalid: false,
    }
  },
  mounted() {
    localStorage.clear()
  },
  methods: {
    approveWalletShare() {
      const params = {
        email: this.$route.query.email,
        input: {
          otp: Number(this.$route.query.otp),
          checksum: this.$route.query.checksum,
          description: this.description,
          decline: this.decline,
        },
      }

      this.$refs.shareWalletProcessForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useApollo.users.processWalletShareInvite(params).then(response => {
            const userData = response.data.processWalletShareInvite
            localStorage.setItem('userData', JSON.stringify(userData.user))
            useJwt.setToken(userData.access_token)
            setTimeout(() => {
              this.$router.replace({ name: 'home' }).then(() => {
                this.$store.dispatch('project/STORE_SELECTED_PROJECT', null)
                this.showSuccessMessage({
                  data: {
                    message: `You have successfully ${this.decline ? 'rejected' : 'accepted'} the wallet share invitation`,
                  },
                })
              })
            }, 500)
          }).catch(() => {
            this.invalid = true
          }).finally(() => { this.isProcessing = false })
        }
      })
    },
  },
}
</script>
